<template>
  <!-- For Article read view Vertical reactions popoup -->
  <div
    v-if="isArticleReadView"
    class="the-verticle-reactions-bar"
  >
    <Transition name="bounce">
      <div class="the-verticle-reactions-bar__reactions" v-if="hoverOnLike">
        <figure class="the-verticle-reactions-bar__reactions--figure">
          <div v-for="reaction in reactions" :key="reaction.name">
            <a-tooltip class="link-tooltip" placement="right">
              <template #title>{{ toTitlecase(reaction.name) }}</template>
              <img
                @click="
                  assignValues(reaction.name, reaction.icon, reaction.mode)
                "
                :src="reaction.icon"
                :alt="reaction.alt"
                :class="`imgHover allreactions`"
                width="auto"
                height="auto"
              />
            </a-tooltip>
          </div>
        </figure>
      </div>
    </Transition>
    <div
      :class="
        `${activeReactionClass} the-verticle-reactions-bar__reaction-status`
      "
      @mouseover="hoverOnLike = true"
      @click="unReact()"
      style=""
    >
      <div
        class="the-verticle-reactions-bar__reaction-status--reactions-container"
      >
        <button class="btn">
          <img
            v-if="!activeReactionIcon"
            src="@/assets/icons/light-bulb.svg"
            alt="icon"
            class="bulb-icon"
            width="auto"
            height="auto"
          />
          <img v-else :src="activeReactionIcon" class="image" />
        </button>
        <p :class="`${activeReactionClass} title`">
          {{ toTitlecase(activeReaction) || "React" }}
        </p>
      </div>
    </div>
  </div>
   <!-- For Mobiled view horizontal reactions popoup -->
   <div v-else-if="isMobileView" 
    @mouseleave="hoverOnLike = false" 
   :class="{'the-horizontal-reactions-bar-mobile' :isMobileView }">
    <div
      class="the-horizontal-reactions-bar"
    >
      <Transition name="bounce">
        <div
          class="the-horizontal-reactions-bar__reactions-bar"
          v-if="hoverOnLike"
        >
          <figure class="the-horizontal-reactions-bar__reactions-bar--figure">
            <div v-for="reaction in reactions" :key="reaction.name">
              <a-tooltip class="link-tooltip" placement="topLeft">
                <template #title>{{ toTitlecase(reaction.name) }}</template>
                <img
                  @click="
                    assignValues(reaction.name, reaction.icon, reaction.mode)
                  "
                  :src="reaction.icon"
                  :alt="reaction.alt"
                  :class="`imgHover allreactions`"
                  width="auto"
                  height="auto"
                />
              </a-tooltip>
            </div>
          </figure>
        </div>
      </Transition>
      <div
        :class="
          `${activeReactionClass} the-horizontal-reactions-bar__reaction-status`
        "
        @mouseover="hoverOnLike = true"
        style=""
        @click="unReact()"
      >
        <div class="the-horizontal-reactions-bar__reaction-status--reactions">
          <div class="image">
            <img
              v-if="!activeReactionIcon"
              src="@/assets/icons/light-bulb.svg"
              alt="icon"
              class="bulb-icon"
              width="auto"
              height="auto"
            />
            <img v-else :src="activeReactionIcon" />
          </div>
          <p :class="`${activeReactionClass} title`">
            {{ toTitlecase(activeReaction) || "React" }}
          </p>
        </div>
      </div>
    </div>
  </div>
   <!-- For Article Card horizontal reactions popoup -->
  <div
    v-else
    @mouseleave="hoverOnLike = false"
    class="the-horizontal-reactions-bar"
  >
    <Transition name="bounce">
      <div
        class="the-horizontal-reactions-bar__reactions-bar"
        v-if="hoverOnLike"
      >
        <figure class="the-horizontal-reactions-bar__reactions-bar--figure">
          <div v-for="reaction in reactions" :key="reaction.name">
            <a-tooltip class="link-tooltip" placement="topLeft">
              <template #title>{{ toTitlecase(reaction.name) }}</template>
              <img
                @click="
                  assignValues(reaction.name, reaction.icon, reaction.mode)
                "
                :src="reaction.icon"
                :alt="reaction.alt"
                :class="`imgHover allreactions`"
                width="auto"
                height="auto"
              />
            </a-tooltip>
          </div>
        </figure>
      </div>
    </Transition>
    <div
      :class="
        `${activeReactionClass} the-horizontal-reactions-bar__reaction-status`
      "
      @mouseover="hoverOnLike = true"
      style=""
      @click="unReact()"
    >
      <div class="the-horizontal-reactions-bar__reaction-status--reactions">
        <div class="image">
          <img
            v-if="!activeReactionIcon"
            src="@/assets/icons/light-bulb.svg"
            alt="icon"
            class="bulb-icon"
            width="auto"
            height="auto"
          />
          <img v-else :src="activeReactionIcon" />
        </div>
        <p :class="`${activeReactionClass} title`">
          {{ toTitlecase(activeReaction) || "React" }}
        </p>
      </div>
    </div>
  </div>
  <subscription-modal
    :visibleSignUp="visibleSubscriptionModal"
    :visible="visibleSubscriptionModal"
    @cancel="visibleSubscriptionModal = false"
    :footer="null"
  ></subscription-modal>
</template>
<script>
import Like from "../../assets/icons/reactions/Like.png";
import Love from "../../assets/icons/reactions/Love.png";
import Laugh from "../../assets/icons/reactions/Laugh.png";
import Insightful from "../../assets/icons/reactions/Insightful.png";
import Support from "../../assets/icons/reactions/Support.png";
import Celebration from "../../assets/icons/reactions/Celebration.png";
import Angry from "../../assets/icons/reactions/Angry.png";
import Sad from "../../assets/icons/reactions/Sad.png";
import SubscriptionModal from "../../components/Auth/AccessModal.vue";
import { mapGetters,mapActions } from "vuex";
export default {
  props: {
    articleId: String,
    userReaction: Object,
    isArticleReadView: Boolean,
    isImpact: Boolean,
    isMobileView: Boolean,
    isInsightArticle: Boolean
  },
  components: {
    SubscriptionModal,
  },
  data() {
    return {
      visibleSubscriptionModal: false,
      reactions: [
        {
          name: "like",
          icon: Like,
          alt: "Like emoji",
          mode: "like",
        },
        {
          name: "love",
          icon: Love,
          alt: "Love emoji",
          mode: "love",
        },
        {
          name: "laugh",
          icon: Laugh,
          alt: "Laugh emoji",
          mode: "laugh",
        },

        {
          name: "sad",
          icon: Sad,
          alt: "Sad emoji",
          mode: "sad",
        },
        {
          name: "angry",
          icon: Angry,
          alt: "Angry emoji",
          mode: "angry",
        },
        {
          name: "insightful",
          icon: Insightful,
          alt: "Insightful",
          mode: "insightful",
        },
        {
          name: "celebrate",
          icon: Celebration,
          alt: "Celebration emoji",
          mode: "celebration",
        },
        {
          name: "support",
          icon: Support,
          alt: "Support emoji",
          mode: "support",
        },
      ],
      hoverOnLike: false,
      activeReaction: "",
      activeReactionIcon: "",
      activeReactionClass: "",
      activeReactionId: "",
    };
  },
  mounted(){
    document.addEventListener('click', this.handleClickOutside);
  },
  computed: {
    ...mapGetters("article", ["getActiveReactionId"]),
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    articles: {
      get(){
        return this.$store.getters["discover/getSearchedArticles"];
      },
      set(val){
        this.$store.commit('discover/SET_ARTICLES',  val);
      }
    },
    insights: {
      get(){
        return this.$store.getters["discover/getInsights"];
      },
      set(val){
        this.$store.commit('discover/SET_INSIGHTS',  val);
      }
    },
    getReactedArticle(){
      return this.$store.getters["article/getReactedArticle"];
    },
    getReactedFeed(){
      return this.$store.getters["feed/getReactedFeed"];
    },
    
  },
  created() {
    if (typeof this.userReaction === "object") {
      const articleReaction = this.reactions.find(
        (reaction) => reaction.name === this.userReaction?.reaction_type
      );
      if (typeof articleReaction === "object") {
        this.activeReaction = articleReaction.name;
        this.activeReactionClass = articleReaction.mode;
        this.activeReactionIcon = articleReaction.icon;
        this.activeReactionId = this.userReaction.reaction;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchFeedList: "feed/fetchFeedList",
    }),
    assignValues(name, icon, mode) {
      if (this.isAuthenticatedUser) {
        this.activeReaction = name;
        this.activeReactionIcon = icon;
        this.activeReactionClass = mode;
        this.sendData();
      } else {
        this.visibleSubscriptionModal = true;
      }
    },
    unReact(){
      // if (this.activeReaction === "" && this.activeReactionIcon === "") {
      //   this.activeReaction = this.reactions[0].name;
      //   this.activeReactionIcon = this.reactions[0].icon;
      //   this.activeReactionClass = "React";
      // } else {
      //   this.activeReaction = "";
      //   this.activeReactionIcon = "";
      //   this.activeReactionClass = "";
      // }
      if(this.activeReaction !== ""){
        this.activeReaction = "";
        this.activeReactionIcon = "";
        this.activeReactionClass = "";
        this.sendData();
      }
    },
    async sendData() {
      if(this.$route.path.includes("network-feed")){
          if(this.activeReaction === ""){
            const payload  ={
              post_id: this.articleId,
            }
            await this.$store.dispatch('feed/deleteFeedReaction', payload);
          } else{
            const payload = {
            post_id: this.articleId,
            reaction: this.activeReaction 
          }
          await this.$store.dispatch('feed/postFeedReaction', payload);
          }
          // this.$emit('update-reaction')
      }else{
        if (this.activeReaction === "") {
        // User wants to remove their reaction
        if (this.activeReactionId) {
          const payload = {
            reactionId: this.activeReactionId,
            articleId: this.articleId,
          };
          const actionType = this.isImpact ? "impact/deleteImpactArticleReaction" : "article/deleteArticleReaction";
          await this.$store.dispatch(actionType, payload);
          this.activeReactionId = null;
          const index = this.articles.findIndex((findArticle) => 
          findArticle.id[0] === this.getReactedArticle.article.id[0]);
          this.articles[index] = {...this.getReactedArticle.article}
          // console.clear()
          // console.log('deleteArticleReaction');
          
        }
      } else {
        // User wants to update their reaction
        if (this.activeReactionId) {
          // Delete the existing reaction
          const deletePayload = {
            reactionId: this.activeReactionId,
            articleId: this.articleId,
          };
          const actionType = this.isImpact ? "impact/deleteImpactArticleReaction" : "article/deleteArticleReaction";
          await this.$store.dispatch(actionType , deletePayload);
        }

        // Add the updated reaction
        const addPayload = {
          reaction: this.activeReaction,
          articleId: this.articleId,
        };
        const actionType = this.isImpact ? "impact/impactArticleReaction" : "article/articleReaction";
        await this.$store.dispatch(actionType, addPayload);
        this.activeReactionId = this.getActiveReactionId;

        let targetArray = this.isInsightArticle ? this.insights : this.articles;

        const index = targetArray.findIndex((findArticle) =>
          findArticle.id[0] === this.getReactedArticle.article.id[0]);
            
          targetArray[index] = { ...this.getReactedArticle.article }
          this.$emit('update-reaction')
          // console.clear()
          // console.log('articleReaction');
      }
      } 
    },
    toTitlecase(value) {
      return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
    },
    handleClickOutside(event) {
      const element = this.$el; 
      if (!element.contains(event.target)) {
        this.hoverOnLike = false
      }
    },
  },
};
</script>

<style lang="scss">
.the-horizontal-reactions-bar {
  width: 100%;
  margin: 1.5rem 0.3rem;
  @include respond(phone-x-small) {
    margin: 1rem 0 !important;
  }
  &__reactions-bar {
    position: relative;
    &--figure {
      overflow: hidden;
      border-radius: 9rem;
      background: $color-white;
      position: absolute;
      top: -4rem;
      padding: 0 0.5rem;
      display: flex;
      left: 0;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.123) 0px 3px 6px;
      z-index: 444;
      img {
        transition: 0.2s ease;
        height: 2.5rem;
        width: 2.5rem;
        margin: 0.8rem 0.5rem;
      }
      .imgHover:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }
  &__reaction-status {
    cursor: pointer;
    &--reactions {
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        img {
          width: 2rem;
          height: auto;
          margin-right: 1rem;
          margin-bottom: 0.2rem;
          @include respond(tab-land) {
            width: 1.6rem;
          }
          @media (min-width: 1200px) and (max-width: 1300px) {
            margin-right: 0.5rem;
          }
        }
        .bulb-icon {
          width: 2rem;
        }
      }
      .title {
        font-size: 1.3rem;
        font-family: $font-primary-medium;
        line-height: 2rem;
        color: $color-black;
        margin-bottom: 0;
        @include respond(tab-land) {
         font-size: 1.3rem;
        }
      }
      .like {
        color: #5e8ffd;
      }
      .love {
        color: #ff4141;
      }
      .laugh {
        color: #c99d24;
      }
      .celebration {
        color: #447eb4;
      }
      .insightful {
        color: #c99d24;
      }
      .support {
        color: #67b82f;
      }
      .sad {
        color: #c99d24;
      }
      .angry {
        color: #fd754b;
      }
    }
  }
}
.the-verticle-reactions-bar {
  &__reactions {
    position: relative;
    &--figure {
      overflow: hidden;
      border-radius: 9rem;
      background: #ffffff;
      position: absolute;
      top: 33%;
      padding: 1rem 1rem;
      display: flex;
      flex-direction: column;
      left: 6.3rem;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.123) 0px 3px 6px;
      z-index: 4;
      transform: translateY(-33%);
      img {
        transition: 0.2s ease;
        height: 3rem;
        width: 3rem;
        margin: 0.8rem 0.5rem;
      }
      .imgHover:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }
  &__reaction-status {
    width: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--reactions-container {
      .btn {
        background-color: #d9d9d9;
        width: 6.3rem;
        height: 6.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 100% !important;
        margin-bottom: 0.4rem;
        @include respond(laptop-large) {
          width: 5rem;
          height: 5rem;
        } 
        img {
          width: 2.3rem;
          height: auto;
          @include respond(laptop-large) {
            width: 1.7rem;
          } 
        }
      }
      .title {
        font-size: 1.6rem;
        font-family: $font-primary-medium;
        margin-bottom: 0;
        line-height: normal;
        color: $color-black;
        @include respond(laptop-large) {
          font-size: 1.4rem;
        } 
      }
      .like {
        color: #5e8ffd;
      }
      .love {
        color: #ff4141;
      }
      .laugh {
        color: #c99d24;
      }
      .celebration {
        color: #447eb4;
      }
      .insightful {
        color: #c99d24;
      }
      .support {
        color: #67b82f;
      }
      .sad {
        color: #c99d24;
      }
      .angry {
        color: #fd754b;
      }
    }
  }
}
.the-horizontal-reactions-bar-mobile {
  .the-horizontal-reactions-bar {
    &__reactions-bar {
      &--figure {
        top: -6rem;
        left: 20%;
        transform: translateX(-20%);
        img {
          transition: 0.2s ease;
          height: 4.5rem;
          width: 4.5rem;
          margin: 0.8rem 0.5rem;
        }
      }
    }
    &__reaction-status {
      cursor: pointer;
      &--reactions {
        flex-direction: column;
        .image {
          background-color: #d9d9d9;
          width: 7rem;
          height: 7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          cursor: pointer;
          border-radius: 100% !important;
          margin-bottom: 1rem;
          img {
            width: 2.3rem;
            height: auto;
            margin-right: 0;
          }
        }
        .title {
          font-size: 2.1rem;
          font-family: $font-primary-medium;
          line-height: 2rem;
          color: $color-black;
          margin-bottom: 0;
        }
        .like {
          color: #5e8ffd;
        }
        .love {
          color: #ff4141;
        }
        .laugh {
          color: #c99d24;
        }
        .celebration {
          color: #447eb4;
        }
        .insightful {
          color: #c99d24;
        }
        .support {
          color: #67b82f;
        }
        .sad {
          color: #c99d24;
        }
        .angry {
          color: #fd754b;
        }
      }
    }
  }
}
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
